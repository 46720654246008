import { Marker } from 'react-map-gl';
import RoomIcon from '@mui/icons-material/Room';
import React, { useRef } from 'react';

export default function CustomMarker(props) {
  const {
    station,
    index,
    multiSelectMode,
    onActivateMultiSelect,
    onSelectStation,
    handler,
    isSelected,
    isChild,
    markerColor,
  } = props;

  const pressTimer = useRef(null);
  const longPressTriggered = useRef(false);

  const scale = isSelected ? 1.2 : 1;

  const style = {
    padding: '0px',
    margin: '0px',
    color: markerColor,
    transform: `scale(${scale})`,
    transition: 'all 0.1s ease',
    filter: 'drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.5))',
  };

  const textStyle = {
    position: 'absolute',
    top: '1em',
    padding: '4px 6px',
    margin: '0px',
    color: '#fff',
    fontSize: '1.8em',
    textShadow: '2px 2px 5px rgba(0,0,0,0.7)',
    transition: 'all 0.1s ease',
  };

  const floatStyle = {
    position: 'relative',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  };

  const startPress = () => {
    longPressTriggered.current = false;
    pressTimer.current = setTimeout(() => {
      longPressTriggered.current = true;
      if (onActivateMultiSelect) {
        onActivateMultiSelect();
      }
      if (onSelectStation) {
        onSelectStation(station.ID);
      }
    }, 2000);
  };

  const endPress = () => {
    clearTimeout(pressTimer.current);
  };

  const handleClick = () => {
    if (longPressTriggered.current) {
      longPressTriggered.current = false;
      return;
    }

    if (multiSelectMode && onSelectStation && !isChild) {
      onSelectStation(station.ID); // Only select if not a child
    } else if (handler) {
      handler();
    }
  };

  return (
    <Marker
      key={index}
      longitude={station.longitude}
      latitude={station.latitude}
      anchor="bottom"
    >
      <div
        data-testid={`marker-${station.ID}`}
        data-selected={isSelected ? 'true' : 'false'}
        data-color={markerColor}
        data-station-id={station.ID}
        style={floatStyle}
        onPointerDown={startPress}
        onPointerUp={endPress}
        onPointerLeave={endPress}
        onClick={handleClick}
      >
        <div id={index} className="mapMarkerStyle" style={style}>
          <RoomIcon style={{ fontSize: '3em', transition: 'all 0.1s ease' }} />
        </div>
        <div style={textStyle}>
          {station.satelliteID} - {station.stationID} {isChild ? 'C' : ''}
        </div>
      </div>
    </Marker>
  );
}
