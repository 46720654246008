import React, { useState, useEffect, useMemo, useRef } from 'react';
import mapboxgl from 'mapbox-gl';
import ReactMapGL from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import APIClient from '../../api.service';
import CustomMarker from '../Map/Components/Marker';
import { Spin, TimePicker } from 'antd';
import StationModal from '../../Screens/Configuration/Modals/StationModal';
import moment from 'moment';
import './Map.css';
import appConfig from '../../config';
import LongPressToast from './Components/LongPressToast';
import Legend from './Components/Legend';

mapboxgl.accessToken = appConfig.REACT_APP_MAPBOX_TOKEN;

export default function MapBoxView() {
  const [config, setConfig] = useState({});
  const [lng] = useState(appConfig.REACT_APP_LON);
  const [lat] = useState(appConfig.REACT_APP_LAT);
  const [zoom] = useState(15.95);
  const [multiSelectMode, setMultiSelectMode] = useState(false);
  const [selectedStations, setSelectedStations] = useState([]);
  const [sharedRunTime, setSharedRunTime] = useState(0);
  const [modalStation, setModalStation] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [showMapInstructions, setShowMapInstructions] = useState(null);
  // Ref to store timeout id from cancelSelection
  const cancelTimeoutRef = useRef(null);

  useEffect(() => {
    const controller = new AbortController();

    const fetchConfigAsync = async () => {
      const apiClient = new APIClient();
      const response = await apiClient.getConfiguration({
        signal: controller.signal,
      });
      setConfig((prevConfig) => {
        if (JSON.stringify(prevConfig) !== JSON.stringify(response)) {
          return response;
        }
        return prevConfig;
      });
    };

    // Initial fetch
    fetchConfigAsync();
    // Polling using setInterval every 10 seconds
    const intervalId = setInterval(fetchConfigAsync, 10000);

    // Read local storage for longpress toast
    const storedInstructions = localStorage.getItem('noLongPressInstructions');
    setShowMapInstructions(storedInstructions);

    return () => {
      clearInterval(intervalId);
      controller.abort();
    };
  }, []);

  useEffect(() => {
    if (multiSelectMode) {
      setTimeout(() => setIsVisible(true), 50);
    } else {
      setIsVisible(false);
    }
  }, [multiSelectMode]);

  const handleSelectStation = (stationID) => {
    setSelectedStations((prev) => {
      if (prev.includes(stationID)) {
        return prev.filter((id) => id !== stationID);
      }
      return [stationID, ...prev];
    });
  };

  const activateMultiSelect = () => setMultiSelectMode(true);

  const cancelSelection = () => {
    setIsVisible(false);
    // Save the timeout id so it can be cleared on unmount
    cancelTimeoutRef.current = setTimeout(() => {
      setSelectedStations([]);
      setSharedRunTime(0);
      setMultiSelectMode(false);
      cancelTimeoutRef.current = null;
    }, 300);
  };

  // Clear pending cancelSelection timeout on unmount
  useEffect(() => {
    return () => {
      if (cancelTimeoutRef.current) {
        clearTimeout(cancelTimeoutRef.current);
      }
    };
  }, []);

  const openModal = (station) => {
    if (!multiSelectMode) {
      setModalStation(station);
    }
  };

  const handleRunTimeChange = (value) => {
    const seconds = value ? value.diff(moment().startOf('day'), 'seconds') : 0;
    setSharedRunTime(seconds);
  };

  const handleSaveSelection = async () => {
    const apiClient = new APIClient();
    const updatePromises = selectedStations.map((stationID) => {
      const station = config.stations.find((s) => s.ID === stationID);
      if (!station) return null;

      return apiClient.updateStation({
        ...station,
        runTime: sharedRunTime,
        priority: 1,
      });
    });
    await Promise.all(updatePromises.filter(Boolean));
    cancelSelection();
  };

  function setMarkerColor(station) {
    const isSelected = selectedStations.includes(station.ID);

    let baseColor;
    if (isSelected) {
      baseColor = 'rgb(255,215,0)';
    } else if (
      station?.stationStatus !== null &&
      parseInt(station?.stationStatus) === 1
    ) {
      baseColor = 'rgb(0,255,0)';
    } else if (!station?.runTime || station?.runTime === '0') {
      baseColor = 'rgb(255,0,0)';
    } else if (station?.runTime !== '0') {
      baseColor = 'rgb(108, 177, 255)';
    }

    return baseColor;
  }

  function darkenColor(rgbStr, factor) {
    const [r, g, b] = rgbStr.match(/\d+/g).map(Number);
    return `rgb(${Math.floor(r * factor)}, ${Math.floor(g * factor)}, ${Math.floor(b * factor)})`;
  }

  // Memoize markers so they only re-render when config, selection, or multiSelectMode change
  const markers = useMemo(() => {
    if (!config?.stations) return null;
    const stationMap = new Map(config?.stations.map((s) => [s.ID, s]));

    const sortedStations = config?.stations
      .slice()
      .sort((a, b) => a?.parentID - b?.parentID);

    return sortedStations.map((station, index) => {
      const isChild = station?.parentID != null;
      let baseColor = setMarkerColor(station);

      if (isChild) {
        const parentStation = stationMap.get(station.parentID);
        const parentColor = setMarkerColor(parentStation);
        baseColor = darkenColor(parentColor, 0.6);
      }

      return (
        <CustomMarker
          key={station.ID}
          index={index}
          station={station}
          isChild={station.parentID != null}
          multiSelectMode={multiSelectMode}
          onActivateMultiSelect={activateMultiSelect}
          onSelectStation={handleSelectStation}
          isSelected={selectedStations.includes(station.ID)}
          handler={() => openModal(station)}
          markerColor={baseColor}
        />
      );
    });
  }, [config.stations, multiSelectMode, selectedStations]);

  if (!config.stations) {
    return (
      <Spin
        style={{ justifyContent: 'center', width: '100%' }}
        tip="Loading..."
      />
    );
  }

  return (
    <>
      {!showMapInstructions && <LongPressToast />}
      <div style={{ height: '100%', position: 'relative' }}>
        <Legend />
        <ReactMapGL
          reuseMaps={true}
          initialViewState={{ longitude: lng, latitude: lat, zoom: zoom }}
          style={{ width: '100%', height: '100%' }}
          mapStyle="mapbox://styles/mapbox/satellite-v9"
          mapboxAccessToken={appConfig.REACT_APP_MAPBOX_TOKEN}
        >
          {markers}
        </ReactMapGL>

        {modalStation && (
          <StationModal
            station={modalStation}
            stationTypes={config.stationTypes}
            autoShow={true}
            onClose={() => setModalStation(null)}
          />
        )}

        {/* Multi-select modal */}
        {multiSelectMode && (
          <div className={`dialog-container ${isVisible ? 'visible' : ''}`}>
            <div className="dialog-header">
              <span className="header-text">Multi Select Mode</span>
              <span className="close-button" onClick={cancelSelection}>
                ×
              </span>
            </div>

            <div className="dialog-body">
              <p>
                <strong>Selected Stations:</strong> {selectedStations.length}
              </p>
              <div className="station-list">
                <div className="stations-grid">
                  {selectedStations.map((stationID, index) => {
                    const station = config.stations.find(
                      (s) => s.ID === stationID,
                    );
                    if (!station) return null;
                    return (
                      <div
                        key={stationID}
                        className="station-item"
                        style={{
                          animationName: 'slideIn',
                          animationDuration: '0.3s',
                          animationFillMode: 'forwards',
                          animationDelay: `${index * 50}ms`,
                          opacity: 0,
                        }}
                      >
                        Satellite {station.satelliteID} - Station{' '}
                        {station.stationID}
                      </div>
                    );
                  })}
                </div>
                <div className="runtime-section">
                  <label className="runtime-label">Run Time:</label>
                  <TimePicker
                    format="mm:ss"
                    allowClear={false}
                    minuteStep={1}
                    secondStep={15}
                    value={moment()
                      .startOf('day')
                      .add(sharedRunTime, 'seconds')}
                    onChange={handleRunTimeChange}
                    className="runtime-picker"
                  />
                </div>
              </div>
            </div>

            <div className="dialog-buttons">
              <button className="save-button" onClick={handleSaveSelection}>
                Save
              </button>
              <button className="cancel-button" onClick={cancelSelection}>
                Cancel
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
