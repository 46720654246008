import { Card } from 'antd';
import './Legend.css';

export default function Legend() {
  return (
    <Card
      title="Legend"
      type="inner"
      className="legend-card"
      data-testid="map-legend"
    >
      <div className="legend-item">
        <div
          className="color-box"
          style={{ backgroundColor: 'rgb(0,255,0)' }}
        ></div>
        <span className="text-content">Currently Running</span>
      </div>
      <div className="legend-item">
        <div
          className="color-box"
          style={{ backgroundColor: 'rgb(255,0,0)' }}
        ></div>
        <span className="text-content">Not Configured To Run</span>
      </div>
      <div className="legend-item">
        <div
          className="color-box"
          style={{ backgroundColor: 'rgb(108, 177, 255)' }}
        ></div>
        <span className="text-content">Configured To Run</span>
      </div>
      <div className="legend-item">
        <div
          className="color-box"
          style={{ backgroundColor: 'rgb(255,215,0)' }}
        ></div>
        <span className="text-content">Multi-Select Mode</span>
      </div>
      <p className="text-content">
        Child Stations are a Darker Shade of Their Parent
      </p>
    </Card>
  );
}
